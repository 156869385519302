/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI300M
 * 화면설명: 가입설계관리 > 사전결재현황
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" type="subpage" title="대체/유의계약 면담현황(FC용)" :show-title="true" :topButton="true"
  @on-scroll-bottom="fn_ScrollBottomHandler">
    <!-- Content영역 -->
    <template #frame-header-fixed>
      <!-- 상세 검색 button -->
      <ur-box-container direction="column" alignV="start" class="topTip2"> 
        <ul class="bullet-type-3 crTy-bk7 fs14rem">
          <li>대체/유의계약 면담현황을 조회할 수 있습니다.</li>
          <li>고객의 가입의사 최종 확인 후, 면담요청 바랍니다.</li>
          <li>최초 조회 시 [면담요청] 건만 조회됩니다. 면담상태 변경하여 확인 바랍니다.</li>
        </ul>
      </ur-box-container>
      <ur-box-container direction="row" alignV="start" class="directiveButtonWrap"> 
        <mo-icon class="refreshIcon" icon="refreshIcon" @click="initDirective">refreshIcon</mo-icon>
        <div class="directiveButtons">
          <!--<msp-bottom-select :items="selectState" v-model="state1" class="ns-dropdown-sheet directiveDropdown selectStateDropdown" :placeholder="state1" bottom-title="결재여부" closeBtn @input="directiveDropdownInput1" />-->
          <msp-bottom-select :items="selectState" v-model="state1" class="ns-dropdown-sheet directiveDropdown selectStateDropdown checked" :placeholder="state1" bottom-title="면담현황" closeBtn @input="directiveDropdownInput1" />
          <mo-button class="directiveButton approvalDateBtn" @click="openApprovalDatePopup">{{requestDate}}</mo-button>
          <mo-button class="directiveButton planTypeBtn" @click="openPlanTypePopup">{{planType}}</mo-button>
        </div>
      </ur-box-container>
      <!-- 상세 검색 button 끝 -->
      <!-- 건수, 정렬조건 TODO 정렬부분은 손 봐야함-->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title align-item-center">
              <span class="sum">총 <span class="count">{{resultCnt}}</span> 건</span>
              <!-- 리스트 정렬 컴포넌트 -->
              <PSListSortOpt ref='sortFieldComp'
                            defaultLabel='정렬선택'
                            popupTitle="정렬선택"
                            itemValue="name" 
                            itemText="label"
                            :sortFieldList='sortFieldList'
                            @sortChange='fn_SortChangeHandler'/>
              <!--/ 리스트 정렬 컴포넌트 -->
            </div>
          </div>
        </mo-list-item> 
      </ur-box-container>
      <!-- 건수, 정렬조건 끝-->
    </template>
    <!-- Data 영역(지점장/FC data 구분) -->
    <ur-box-container v-if="!isNodata" direction="column" alignV="start" class="msp-ps-wrap">      
      <ur-box-container direction="column" alignV="start">
        <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand">
          <ur-box-container alignV="start" componentid="" direction="column">     
            <!-- 여기부터LIST로 바꾸어야해 -->
            <mo-list :list-data="viewData">
              <template #list-item="{item,index}">      
              	<mo-list-item>
                  <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100 mt20" btn-area-class="fexTy3-1 mb0">
                    <template #title>
                      <!-- 내가추가한부분시작 -->
                        <div class="list-item__contents__title pt6">
                          <span class="txtSkip fs19rem fwb">{{item.prdtNm}}</span> <!--상품명-->
                        </div>
                        <div class="mt10 mb4">
                          <span class="contentsHead1 min50">피보험자</span> 
                          <span class="contentsData1">{{item.mnIsdNm}}</span>
                          <em class="em_normal">|</em>
                          <span class="contentsHead1 min40">청약서 발행일</span><span class="contentsData1 expandItem">{{item.contYmdLabel}}</span>
                      </div>  
                      <div class="mt10">
                         <span class="contentsHead1">보험료</span> 
                         <span class="crTy-blue3 fs16rem fwb">{{$bizUtil.numberWithCommasCurr(item.prm, item.crncyCd, 'Y', 'Y', 3)}}</span>
                      </div>
                      <!-- 내가추가한부분종료 -->
                    </template>
                    <!-- 위에까지가 하얀바탕에보이는부분 -->
                    <template #btn>
                      <mo-button class="link-arrow down"></mo-button>
                    </template>
                    <template #content>
                      <div class="list-item-detail gray_style margin--full pt20 pb20 bd-T-Ty1 mt10">
                        <ur-box-container class="con--padding ">
                          <div class="contentsRow mt7">
                              <span class="contentsHead2 min80">영수증번호</span>
                              <span class="contentsData2">{{item.planId}}</span>
                          </div>
                          <div class="contentsRow mt7">
                              <span class="contentsHead2 min80">설계종류</span>
                              <span class="contentsData2">{{item.contTypNm}}</span> 
                          </div>
                          <div class="contentsRow mt7">
                              <span class="contentsHead2 min80">면담여부</span>
                              <span class="contentsData2">{{item.sancStatNm}}</span>
                          </div>
                          <div class="contentsRow mt7">
                              <span class="contentsHead2 min80">승인자</span>
                              <span class="contentsData2">{{item.sancrNm}}</span>
                          </div>
                          <div class="contentsRow mt7">
                              <span class="contentsHead2 min80">승인일</span>
                              <span class="contentsData2">{{item.sancYmdLabel}}</span>
                          </div>
                        </ur-box-container>
                      </div>
                    </template>
                  </msp-expand>
                </mo-list-item>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
                  <div class="ns-btn-relative-area mt20 mb20">
                    <div class="ns-btn-relative-s">
                      <div class="relative-div grid-temp-col31 mb0">
                        <mo-button componentid="" v-if="item.request===true" disabled class="ns-btn-round white ml0 mr0">면담요청완료</mo-button>
                        <mo-button componentid="" v-if="item.request===true" class="ns-btn-round white ml0 mr0" @click="openRequestPopup(item)">재요청</mo-button>
                        <mo-button componentid="" v-if="item.request===false" class="ns-btn-round white ml0 mr0" @click="openRequestPopup(item)">면담요청</mo-button>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- no-data --> 
    <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
      <mo-list-item >
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span class="ns-ftcr-gray"><p class="mt20">데이터가 없습니다.</p></span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
    <!-- //no-data --> 
    <!-- Data 영역 끝 -->
    <!-- content 영역 끝 -->
    <!-- Popup 사전결재 요청 -->
    <mo-bottom-sheet ref="requestPopup" :close-btn="closeBtn"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div class="ns-btn-close" @click="closeRequestPopup" name="닫기"></div>
      </template>     
      <div class="customer-info-txt txt-center">
        <div class="popupMessageSub">해당 계약은 대체/유의계약으로 영업관리자 면담후에 현장출금이 가능합니다.</div><br/> 
        <div class="popupMessageSub">*면담대상 <br/>({{selectContTypNm}})</div><br/> 
        <div class="popupMessageBold">면담을 {{selectPR}}할까요?</div>
        
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
			<mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="closeRequestPopup" class="ns-btn-round white dsD">아니오<span class="dsD fs13rem fwn mt2">(지점 접수만 가능)</span></mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="runRequest(selectStateCd)" class="ns-btn-round blue">예</mo-button>
          </div>
          <!--<div class="popupButtonTip">※ 지점접수만 가능</div>-->
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup 사전결재 요청 끝 -->
    <!-- Popup 결재 요청일 선택 -->
    <mo-bottom-sheet ref="approvalDatePopup" :close-btn="closeBtn"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div>청약서발행일</div>
        <div class="ns-btn-close" @click="closeApprovalDatePopup" name="닫기"></div>
      </template>
       
      <div class="customer-info-txt narrow">
        <div class="ns-date-picker multi">
          <mo-date-picker v-model="dateValue1" init="month-1" placeholder="시작일" /><span>~</span>
          <mo-date-picker v-model="dateValue2" init="today" placeholder="종료일" />
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="selectApprovalDate" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup 결재 요청일 선택 끝 -->
    <!-- Popup 설계종류 선택 -->
    <mo-bottom-sheet ref="planTypePopup" :close-btn="closeBtn"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div>설계종류</div>
        <div class="ns-btn-close" @click="closePlanTypePopup" name="닫기"></div>
      </template>  
      <div class="customer-info-txt narrow">
        <div class="planTypePopupDropdownWrap">
          <msp-bottom-select :items="plans1" :itemValue="'key'" :itemText="'label'" v-model="planType1"  @selectedItem="fn_selectedItem(planType1, 'plans1')"  underline class="ns-dropdown-sheet" placeholder="구분" bottom-title="설계 구분" closeBtn />
          <msp-bottom-select :items="plans2" :itemValue="'key'" :itemText="'label'" v-if="this.planTypeDisabled === false" :disabled="planTypeDisabled" v-model="planType2" underline class="ns-dropdown-sheet" placeholder="구분" bottom-title="설계 구분" closeBtn />
         </div>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="selectPlanType()" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup 설계종류 선택 끝 -->

    <!-- 토스트 -->
    <mo-snackbar ref="stackableSnackbar" stackable :duration="5000" /> 
  </ur-page-container>
</template>
<script>

/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSListSortOpt from '@/ui/ps/comp/PSListSortOpt'
import PSHeaderSubMenuPopup from '@/ui/ps/comp/PSHeaderSubMenuPopup'
import Screen from '~systems/mixin/screen'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import Gaf from '@/ui/pi/common/Gaf'
import UrBoxContainer from '~/src/_uidev/containers/box/urBoxContainer.vue';

  export default {
    /***********************************************************************************
    * Vue 파일 속성 정의 영역
    * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
    * - screenId : 화면ID
    * - components : UI TAG 컴포넌트 정의
    ***********************************************************************************/
    name: 'MSPPI300M',
    screenId: 'MSPPI300M',
    mixins: [Screen],
    components: {
      'PSNodataMesgBoxForList'  : PSNodataMesgBoxForList, //데이터없음 메세지 출력 박스
      'PSListSortOpt'           : PSListSortOpt, // 정렬 컴포넌트
    },
    /***********************************************************************************
    * 화면 UI Property 정의
    * - 파리미터 받는 변수 props 정의
    ***********************************************************************************/
    props:{
      closeBtn:{
        type:Boolean,
        default:false
      },
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/ 
    beforeCreate () {},//beforeCreate
    created () {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    },
    // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
    beforeUpdate () {},//beforeUpdate

    // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
    updated () {},//updated

    // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
    beforeDestroy () {},//beforeDestroy

    // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
    destroyed () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    },//destroyed
    mounted () {
    //초기데이터조회
    this.fn_Reset()  
    this.fn_ServiceData('S')

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 화면변수 선언 영역 TODO추후에 필요한것들로 재정비                                  *
     ***********************************************************************************/
    data () {
      return {
        stndKey             : '', // TODO 페이징 Next Key
        isNodata            : false, // TODO 데이터 없음 Flag            
        rsltCnt             : 0 ,  // TODO 조회결과 누적 데이터 길이
        sortFieldList       : [
            {field : 'contYmdLabel'    , label : '청약서발행일자'    , sort: 'DESC'},
            {field : 'mnIsdNm'        , label : '고객명'           , sort: 'ASC'},
            {field : 'planId'         , label : '영수증번호'       , sort: 'ASC'},
            {field : 'contTypNm'      , label : '설계유형'         , sort: 'ASC'}
        ], // TODO 정렬 필드 옵션 리스트
        orgItems            : [], // TODO 전체 리스트 목록 복사본
        //actionSlot          : '', // 고객명 검색 slot
        show                : true,
        sorting             : '', // TODO정렬
        sortingItems        : [], // TODO
        resultCnt           : 0,     // 조회된 데이터 총 건수
        state1              : '14', //결재상태로 결재요청전이 디폴트(10.결재중 12.결재완료 14.결재요청전 빈값:전체)
        requestDate         : '청약서발행일',
        planType            : '설계종류',
        planTypeDisabled: true,
        viewData               : [], // 전체 리스트 (화면에 보여지는 데이터)
        selectItems         : [], // 결재시 선택목록
        tempSelectAray      : [], // 상단필터데이터
        selectContTypNm     : '',
        selectPR : '',
        selectStateCd : '',
        selectState: [
          { value: ' ' , text: '전체' },
          { value: '14', text: '면담전' },
          { value: '10', text: '면담중' },
          { value: '12', text: '면담완료' },
        ], //결재상태
        dateValue1:'',
        dateValue2:'', 
        planType1:'',
        planType2:'',
        plans1: [
          { key: ' ' , label: '총량' },
          { key: 'AA' , label: '유의계약' },
          { key: '84', label: '대체계약' }
        ], // 설계종류
        plans2: [
          //{ key: ' ' , label: '총량' },
          { key: '' , label: '구분' }
        ],
        plans3: [
       	   { key: 'BB', label: '총량' },
       	   { key: '82', label: '분산모집계약' },
       	   { key: '81', label: '단기반복해지계약' },
       	   { key: '80', label: '본인계약' },
       	   { key: '83', label: 'Just달성계약' }

       	 ],
         plans4: [
           { key: '' , label: '구분' }
         ],
         plansMap: [
           { key: ' ' , label: '총량' },
           { key: 'BB', label: '유의계약' },
       	   { key: '80', label: '본인계약' },
       	   { key: '81', label: '단기반복해지계약' },
       	   { key: '82', label: '분산모집계약' },
       	   { key: '83', label: 'Just달성계약' },
       	   { key: '84', label: '대체계약' }
        ]
      }
    },

    /***********************************************************************************
     * Computed 함수 정의 영역     
     ***********************************************************************************/
    computed: {
    },
    /***********************************************************************************
    * watch 정의 영역
    * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
    *   (변수에 대한 값 변경시 반응형 콜백 처리)
    *   ex) data 영역 정의한 익명변수 userNm 
    *       userNm : function() { this.message + ' 님 안녕하세요!'}
    ***********************************************************************************/
    watch: {
      viewData() {
        // 총 건수 셋팅
        this.resultCnt = this.viewData.length
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      onSelect(value) {
        this.selectedItem = this.$refs.bottomSelect.getSelectedItem()
      },
      moreShow() {
        this.show = !this.show
      },
      open() { this.$refs.nsbottomsheet.open()},
      close() { this.$refs.nsbottomsheet.close()},
      
    /***********************************************************************************
    * TODO 상단의 select영역 초기화 버튼 클릭이벤트
    ***********************************************************************************/
    initDirective() {
      // TODO전체초기화할때 추가해야해 하단 선택내용도 초기화되어야함
      let today = piCommonUtil.getCurrentDate('YYYY-MM-dd').substring(0, 10).replace(/-/gi, '')
      let startDate = PSDateUtil.fn_AddMonth(today,-1) // 시작일자(현재일자-1개월) 초기로드시에만......현재일자 -1이어야함..
      this.state1 = '14' //결재요청전 상태 
      this.requestDate = '청약서발행일'
      this.planType = '설계종류'
      this.dateValue1 = startDate
      this.dateValue2 = today
      this.planType1 = ''
      this.planType2 = ''
      // TODO전체초기화할때 추가해야해 하단 선택내용도 초기화되어야함
      //document.querySelector('.directiveDropdown.selectStateDropdown').classList.remove('checked')

      let directiveButtons = document.getElementsByClassName('directiveButton')
      for (let i = 0; i < directiveButtons.length; i++){
        directiveButtons[i].classList.remove('checked')
      }
      this.fn_Reset()
      this.fn_ServiceData('S')
    },
    openRequestPopup(pData) {
      this.selectItems = [] // 선택 리스트
      this.selectItems.push(pData)
      //결재상태코드에 따른 servicename 
      if(this.selectItems[0].sancStatCd === '10') {  //결재중 재요청 활성화
        this.selectStateCd = 'R'
      }else if (this.selectItems[0].sancStatCd === '12'){ //결재완료 재요청 활성화 
        this.selectStateCd = 'R'
      }else if (this.selectItems[0].sancStatCd === '14'){ //결재요청전 (결재요청전 버튼만 활성화)
        this.selectStateCd = 'P'
      } 
      this.selectPR = (this.selectStateCd === 'P') ? '요청' : '재요청' //2025.04.08  

      this.fn_makeContTypTxt(this.selectItems)
      this.$refs.requestPopup.open()
    },
    closeRequestPopup() {
      this.$refs.requestPopup.close()
    },
    runRequest(serviceName) {
      if(serviceName ==='P' || serviceName ==='R'){
    	  this.fn_ServiceData(serviceName)
    	  this.$refs.stackableSnackbar.show({text:'면담요청을 하였습니다.'})
    	  this.$refs.requestPopup.close()
      }else{
    	  this.$refs.requestPopup.close()
      }
      this.$refs.requestPopup.close()
    },
    directiveDropdownInput1() {
      this.fn_SelectSearch()   
      //this.fn_ServiceData('S')
      document.querySelector('.directiveDropdown.selectStateDropdown').classList.add('checked') 
    },
    openApprovalDatePopup() {this.$refs.approvalDatePopup.open()},
    closeApprovalDatePopup() {this.$refs.approvalDatePopup.close()},
    selectApprovalDate() {
      this.requestDate = this.dateValue1 + " ~ " + this.dateValue2
      // TODO데이터 연결후 테스트 
      this.fn_SelectSearch()   
      document.querySelector('.directiveButton.approvalDateBtn').classList.add('checked') 
      this.$refs.approvalDatePopup.close()
    },
	openPlanTypePopup() {this.$refs.planTypePopup.open()},
    closePlanTypePopup() {this.$refs.planTypePopup.close()},
    selectPlanType() {
      if(this.planType1 === 'AA'){
    	  	if(piCommonUtil.isEmpty(this.planType2)){
    	  		let tempMsg = '소분류를 선택하세요.'
                piCommonUtil.getFDPConfirm('m', tempMsg)
                return false   	  		
    	  	}else{
    	  		this.planType = this.plansMap.filter((itemPlans) => { return itemPlans.key === this.planType2 })[0].label 
    	  	}
    	}else if(this.planType1 === ' ' || this.planType1 === '84'){
    		this.planType = this.plansMap.filter((itemPlans) => { return itemPlans.key === this.planType1 })[0].label
    	}else{
    		this.planType = '설계종류'
    	}
       this.fn_SelectSearch()    
      document.querySelector('.directiveButton.planTypeBtn').classList.add('checked')
      this.$refs.planTypePopup.close()
    },	
      
      /*---------------------------------------------------------------------------------
       * Data Service 핸들러 영역
       ---------------------------------------------------------------------------------*/
       /******************************************************************************
       * TODO 신규IF로 바꾸어야함
       * Function명  : fn_ServiceData
       * 설명        : 서비스 호출 함수 정의
       ******************************************************************************/
		fn_ServiceData(serviceName) {
		
			// 더보기 마지막 조회때 stndKey = '' 으로 되기때문에 마지막 검색으로 판단하고 추가 조회 안함
			if (serviceName === 'N' && this.stndKey === '') return
			if(!this.fn_ChkValid(serviceName)) return false
		
			let pParams = {}
			pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS30S1')) // 신규PR 등록후 변경하기!
			pParams.data = {}
			pParams.data.ZAtntRplcContSancPnstaDto = {}
			switch (serviceName) {
			case 'S': // 사전결재현황 조회
			case 'N': // 사전결재현황 더보기
				this.fn_SortReset()  

				pParams.data.ZAtntRplcContSancPnstaDto.trtScCd = 'S' // 처리구분코드
				let today = piCommonUtil.getCurrentDate('YYYY-MM-dd').substring(0, 10).replace(/-/gi, '')
				let startDate = PSDateUtil.fn_AddMonth(today,-1) // 시작일자(현재일자-1개월) 초기로드시에만......현재일자 -1이어야함..
				pParams.data.ZAtntRplcContSancPnstaDto.pblStartYmd = (this.dateValue1)? this.dateValue1.replace(/-/gi, '') : startDate // 종료일자 
				pParams.data.ZAtntRplcContSancPnstaDto.pblEndYmd = (this.dateValue2)? this.dateValue2.replace(/-/gi, '') : today // 종료일자
				// TODO 계약유형 -> 화면의 선택값 코드값으로 넣도록 변경 
				if(this.planType1 === 'AA'){
					pParams.data.ZAtntRplcContSancPnstaDto.contTypCd = this.planType2
				}else if(this.planType1 === '84'){
					pParams.data.ZAtntRplcContSancPnstaDto.contTypCd  = this.planType1
				}else if(this.planType1 === ' '){
					pParams.data.ZAtntRplcContSancPnstaDto.contTypCd = ''          
				}else{
					pParams.data.ZAtntRplcContSancPnstaDto.contTypCd  = ''
				} 
				pParams.data.ZAtntRplcContSancPnstaDto.sancStatCd = (this.state1)? String(this.state1).trim() : ''
        //초기상태일때는 결재중인것만 조회한다
				if(pParams.data.ZAtntRplcContSancPnstaDto.sancStatCd == '면담전'){
					pParams.data.ZAtntRplcContSancPnstaDto.sancStatCd = '14'
				}
		
				// Legacy PJT 변경. 설계진행현황조회 VO에 페이징 VO 추가. ERP To-Be 수정사항 따라감.
				pParams.data.ZAtntRplcContSancPnstaDto.zPagingDto = {}
				pParams.data.ZAtntRplcContSancPnstaDto.zPagingDto.zaFnctScCd = serviceName
				pParams.data.ZAtntRplcContSancPnstaDto.zPagingDto.zaPageRowCnt = 30
				pParams.data.ZAtntRplcContSancPnstaDto.zPagingDto.zaStndKey = (serviceName === 'S') ? '' : this.stndKey
				if(serviceName === 'S') {
					window.vue.getStore('progress').dispatch('SKEL_LIST_CHK') // or 'FULL'
				}else {          
					window.vue.getStore('progress').dispatch('PART') // or 'SKEL_LIST_CHK'
				}        
				break
		
			case 'P': // 결재요청 (처리구분코드에 이값넣으면됨)
			case 'R': // 결재요청 (처리구분코드에 이값넣으면됨)				
				pParams.data.ZAtntRplcContSancPnstaDto.trtScCd = serviceName // 처리구분코드

				let _zAtntRplcContList = []
				_zAtntRplcContList.push({
					'planId': this.selectItems[0].planId, //영수증번호
					'contTypCd': this.selectItems[0].contTypCd //계약유형코드
				})

				pParams.data.ZAtntRplcContSancPnstaDto.zAtntRplcContList = _zAtntRplcContList
				
				this.fn_Reset()
				break   
		
			default:
				break
			} // end switch
		
			pParams.srnId = this.$options.screenId // 대표 화면 명
			PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
		},
		/*============================ 3 정합성 체크  ====================================*/
		// 입력된 값 유효성 체크 수행 
			fn_ChkValid(trtScCd) {
			switch (trtScCd) {
			case 'S': // 사전결재현황 조회
			case 'N': // 사전결재현황 더보기
				let today = piCommonUtil.getCurrentDate('YYYY-MM-dd').substring(0, 10).replace(/-/gi, '')
				let startYmd = (this.dateValue1)? this.dateValue1.replace(/-/gi, '') : (this.dateValue1)? this.dateValue1.replace(/-/gi, '') : PSDateUtil.fn_AddMonth(today,-1) // 시작일자(현재일자-1개월) 초기로드시에만......현재일자 -1이어야함..
				let endYmd = (this.dateValue2)? this.dateValue2.replace(/-/gi, '') : today
				if (startYmd > today) {
					let tempMsg = '시작일자는 현재일자보다 늦을 수 없습니다'
					piCommonUtil.getFDPConfirm('m', tempMsg)
					return false
				}
				if (startYmd > endYmd) {
					let tempMsg = '시작일자는 종료일자보다 늦을 수 없습니다'
					piCommonUtil.getFDPConfirm('m', tempMsg)
					return false
				}
				break
			case 'P': // 결재요청 (처리구분코드에 이값넣으면됨)
			case 'R': // 결재요청 (처리구분코드에 이값넣으면됨)
				if (trtScCd === 'P' && this.selectItems[0].sancStatCd === '10') {
					let tempMsg = '면담중인 발행건입니다'
					piCommonUtil.getFDPConfirm('m', tempMsg)
					return false
				}
				if (trtScCd === 'R' && this.selectItems[0].sancStatCd === '14') {
					let tempMsg = '면담전인 발행건입니다.'
					piCommonUtil.getFDPConfirm('m', tempMsg)
					return false
				}
				break   
			default:
				break
			} // end switch
			return true
		},  
      /************************************************************************************************
      * Function명  : fn_ServiceResultHandler
      * 설명        : 서비스 호출 결과 함수 정의
      ************************************************************************************************/
		fn_ServiceResultHandler(event, serviceName) {  	  
			let lv_data = event.data
			switch (serviceName) {
			case 'S': // 사전결재현황 조회
			case 'N': // 사전결재현황 더보기          
				this.stndKey = lv_data.zatntRplcContSancPnstaDto.zpagingDto.zaStndKey || ''
				let t_rslt = lv_data.zatntRplcContSancPnstaDto.zatntRplcContList || []


				if (t_rslt && t_rslt.length > 0) {

					t_rslt.forEach(pItem => {
						//실제리턴데이터용 SETTING
						let pItems = {}
						pItems.prdtNm = pItem.prdtNm.replace('삼성생명', '').replace('삼성', '').trim() // 설계명에 삼성생명, 삼성 제거 처리
						pItems.contYmdLabel =  PSDateUtil.fn_DateFormat(pItem.pblYmd, 'MM-dd', PSDateUtil.LV_FORMAT_YYYYMMDDHHMMSS) // 2018-10-19 00:00:00.0 청약서발행일
						pItems.sancYmdLabel = PSDateUtil.fn_DateFormat(pItem.sancYmd, 'MM-dd', PSDateUtil.LV_FORMAT_YYYYMMDDHHMMSS) // 2018-10-19 00:00:00.0 결재일자
						pItems.mnIsdNm = pItem.mnIsdNm //피보험자명
						pItems.prm = pItem.prm //가입금액
						pItems.crncyCd = pItem.crncyCd //화폐코드
						pItems.planId = pItem.planId //영수증번호
						pItems.sancrNm = pItem.sancrNm //결재자명
						pItems.contTypCd = pItem.contTypCd //계약유형코드
						pItems.sancStatCd = pItem.sancStatCd //결재상태코드 
                        pItems.contTypCdList = pItem.contTypCdList //계약유형코드리스트

						//계약유형코드 -> 명으로 변경
						if(pItems.contTypCd === '80') {
							pItems.contTypNm = '본인계약'
						}else if (pItems.contTypCd === '81') {
							pItems.contTypNm = '단기반복해지계약'
						}else if (pItems.contTypCd === '82') {
							pItems.contTypNm = '분산모집계약'
						}else if (pItems.contTypCd === '83') {
							pItems.contTypNm = 'Just달성계약'
						}else if (pItems.contTypCd === '84') {
							pItems.contTypNm = '대체계약'
						}

						//결재상태코드에 따른 버튼활성화유무 및 코드명  
						if(pItems.sancStatCd === '10') { 
							pItems.sancStatNm = '면담중' //결재완료 재요청 활성화
							pItems.request = true
						}else if (pItems.sancStatCd === '12'){ //결재완료 재요청 활성화 
							pItems.sancStatNm = '면담완료'
							pItems.request = true
						}else if (pItems.sancStatCd === '14'){ //결재요청전 (결재요청전 버튼만 활성화)
							pItems.sancStatNm = '면담전'
							pItems.request = false
						} 
						console.log(">>pItem"+pItem)
						this.viewData.push(pItems)
					})
					this.orgItems = this.viewData
					this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
						for(let i = 0 ; i < this.orgItems.length; i++){
							this.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
						}
					});

					// 데이터 없음 처리
					if(this.viewData.length > 0) {
						this.isNodata = false
					}else {
						this.isNodata = true
					}
				} else {
					if (serviceName === 'S') this.fn_Reset()// 조회결과 없음 처리
				}
				break
			case 'P': // 결재요청 (처리구분코드에 이값넣으면됨)
			case 'R': // 재요청
				this.fn_ServiceData('S')
				break
			default:
				break
			} // end switch
		},
    /******************************************************************************
    * Function명  : fn_ServiceFaultHandler
    * 설명        : 서비스 에러 이벤트
    ******************************************************************************/
    fn_ServiceFaultHandler(event, serviceName) {
      switch(serviceName) {
        case 'S':
        case 'N':
          this.fn_Reset()
          this.getStore('confirm').dispatch('ADD', event.msgDesc)
          //this.searchFlag = false
          break
        default:
          break
      }
      window.vue.getStore('progress').dispatch('HIDE')
    },
    /*---------------------------------------------------------------------------------
    * 팝업 및 화면 이동 처리 영역
    ---------------------------------------------------------------------------------*/
    /*---------------------------------------------------------------------------------
    * UI 이벤트 핸들러 영역
    ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
       this.fn_ServiceData('N')
    },// fn_ScrollBottomHandler

    /******************************************************************************
    * Function명  : fn_SortReset
    * 설명        : 정렬 리셋 이벤트 핸들러
    ******************************************************************************/
    fn_SortReset(){
      // 정렬 라벨 초기화
      this.$refs.sortFieldComp.fn_Reset()
    },
    /******************************************************************************
    * Function명  : fn_SortChangeHandler
    * 설명        : 정렬 이벤트 핸들러
    *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
    ******************************************************************************/
    fn_SortChangeHandler (pSortOrder){
      console.log('fn_SortChangeHandler !!!')

      let sortField = pSortOrder.field
      let sortOrderby = pSortOrder.sort //ASC or DESC

      console.log(pSortOrder)
      console.log('sortField = '+sortField+ ' , sortOrderby = '+sortOrderby)
      
      switch(sortField){       
        case 'contYmdLabel': // 날짜형 String 을 Date 형으로 변환 처리 해서 sort
          //PSCommUtil.fn_SortListValueDateString(sortOrderby, this.items, sortField, 'yyyy-MM-dd') //yyyy-MM-dd
          PSCommUtil.fn_SortListValueString(sortOrderby, this.viewData, sortField)
          break
        case 'mnIsdNm':  //String 형 sort
        case 'contTypNm':  //String 형 sort
        case 'planId':  //String 형 sort
          PSCommUtil.fn_SortListValueString(sortOrderby, this.viewData, sortField)
          break
      }//switch

    },
     /******************************************************************************
    * Function명 : fn_SelectSearch
    * 설명 : 조회 Data 필터 처리
    * TODO 중복으로 필터링 가능한데 그 처리 추가로 해줘야함 
    ******************************************************************************/
    fn_SelectSearch()  {
      //날짜이외에도 선택한 인풋으로 그냥 다시 조회해도 되지않을까...이렇게 할필요 없을듯.
      /*if(changeCd === '3' && requestDate1 !== "" && requestDate2 !== ""){
        this.fn_Reset()
        this.fn_ServiceData('S')
      }
      let tempArray2 = []
      tempArray2 = this.orgItems
      if(changeCd === '1' && (state1!== '결재여부' && state1!== "0")){
        tempArray2 = tempArray2.filter( item => { return item.sancStatCd === state1}) //결재상태
      }
      if(changeCd === '2' && planType !== '설계종류'){
        tempArray2 = tempArray2.filter( item => { return item.contTypNm === planType }) //계약구분
      }

      this.items = []
      this.items = tempArray2*/
      // 정렬 선택 라벨 초기화
      // 인풋바뀌면 재조회 
      this.fn_Reset()
      this.fn_ServiceData('S')
      this.$refs.sortFieldComp.fn_Reset()

    },
      /******************************************************************************
      * Function명  : fn_ScrollBottomHandler
      * 설명        : Scroll Bottom 핸들러
      ******************************************************************************/
      fn_ScrollBottomHandler() {
        this.fn_ServiceData('N')
      },// fn_ScrollBottomHandler
      /******************************************************************************
      * Function명  : fn_DevicBackBtnHandler
      * 설명        : 디바이스 Back Button 핸들러
      ******************************************************************************/
      fn_DevicBackBtnHandler() {
        if(PSCommUtil.fn_IsActiveModal()) return
        this.fn_HeaderBackBtnHandler()
      },
      /******************************************************************************
      * Function명  : fn_HeaderBackBtnHandler
      * 설명        : 상단 타이틀 백 아이콘 클릭 핸드러
      ******************************************************************************/
      fn_HeaderBackBtnHandler() {
        this.$router.push({ name: 'MSPBC002M' })
      },
      /******************************************************************************
      * Function명 : fn_Reset
      * 설명       : 초기화
      ******************************************************************************/
      fn_Reset() {
        this.viewData = [] // 화면에 보이는 리스트
        this.orgItems = [] // 전체 리스트
        this.selectItems = [] // 선택 리스트
        this.selectContTypNm = ''
        this.selectPR = ''
        this.selectStateCd = ''	
        this.stndKey = '' // 리스트 페이징 Next Key
        this.isNodata = true // 데이터 없음 Flag
        this.resultCnt = 0 // 조회된 데이터 총 건수
      },   
      /******************************************************************************
       * Function명 : fn_selectedItem
       * 설명       : 선택항목 selectedItem에 담는다
       ******************************************************************************/
       fn_selectedItem(value, text) {
         if(value === 'AA') {
         	this.planTypeDisabled = false
         	this.plans2 = this.plans3
         } else {
         	this.planTypeDisabled = true
         	this.plans2 = this.plans4
         }
       },
      /******************************************************************************
      * Function명 : fn_makeContTypTxt
      * 설명       : 영수증번호에 해당하는 결재리스트목록을 모두 alert메세지로 보여준다
      ******************************************************************************/
      fn_makeContTypTxt(selectItems){
        let contTypCdList = this.selectItems[0].contTypCdList
        let nowRequst = this.selectStateCd
        let contCdArr = []
        let txtArr = [];
        let contTypTxt = '';
        contCdArr = contTypCdList.split(",")
                
        if (piCommonUtil.isObj((contCdArr) && contCdArr.length > 0)){
                  for (var i=0; i<contCdArr.length; i++) {
                    if (contCdArr[i] === "80") {
                      txtArr.push("본인계약");
                    } else if (contCdArr[i] === "81") {
                      txtArr.push("단기반복해지계약");
                    } else if (contCdArr[i] === "82") {
                      txtArr.push("분산모집계약");
                    } else if (contCdArr[i] === "83") {
                      txtArr.push("Just달성계약");
                    } else if (contCdArr[i] === "84") {
                      txtArr.push("대체계약");
                    }
                  }
                  contTypTxt = txtArr.join(",");
          }
          this.selectContTypNm = contTypTxt
      },
     
    },
  };
</script>